import React, { useEffect, useState } from 'react'
import { projectsData, projectsNav } from './Data'
import WorkItems from './WorkItems'

const Works = () => {
	const [item, setItem] = useState({ name: 'Работа' })
	const [nextItems, setNextItems] = useState(6)
	const [projects, setProjects] = useState([])
	const [active, setActive] = useState(0)
	useEffect(() => {
		if (item.name === 'Все') {
			setProjects(projectsData)
		} else {
			const newProjects = projectsData.filter(projects => {
				return projects.category === item.name
			})
			setProjects(newProjects)
		}
	}, [item])
	const handleClick = (e, index) => {
		setItem({ name: e.target.textContent })
		setActive(index)
	}
	const loadMoreHandler = () => {
		setNextItems(prevValue => prevValue + 3)
	}

	return (
		<div>
			<div className='work__filters'>
				{projectsNav.map((item, index) => {
					return (
						<span
							onClick={e => {
								handleClick(e, index)
							}}
							className={`${active === index ? 'active-work' : ''} work__item`}
							key={index}
						>
							{item.name}
						</span>
					)
				})}
			</div>
			<div className='work__container container grid'>
				{projects.slice(0, nextItems).map(item => {
					return <WorkItems item={item} key={item.id} />
				})}
			</div>
			<div className='section__more'>
				{nextItems < projects.length && projectsData.length > 6 && (
					<button
						onClick={loadMoreHandler}
						className='button-more button-more--flex'
					>
						Показать больше...
					</button>
				)}
			</div>
		</div>
	)
}

export default Works
