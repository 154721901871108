import React from 'react'
import './link.css'

const Link = () => {
	window.addEventListener('scroll', function () {
		const scrollUp = document.querySelector('.link')
		if (this.scrollY >= 0) scrollUp.classList.add('show-link')
		else scrollUp.classList.remove('show-link')
	})
	return (
		<a className='link' href='https://wa.me/79034047932'>
			<i className='uil uil-phone-volume link__icon'></i> Записаться
		</a>
	)
}

export default Link
