import React, { useState } from 'react'
import './services.css'

const Service_1 = () => {
	const [toggleState, setToggleState] = useState(0)
	const toggleTab = index => {
		setToggleState(index)
	}
	return (
		<div className='services__content'>
			<div>
				<i className='bx bx-book-bookmark services__icon'></i>
				<h3 className='services__title'>
					Первичная <br /> Консультация
				</h3>
			</div>
			<span className='services__button' onClick={() => toggleTab(1)}>
				Смотреть<i className='uil uil-arrow-right services__button-icon'></i>
			</span>

			<div
			
				className={
					toggleState === 1 ? 'services__modal active-modal' : 'services__modal'
				}
			>
				<div className='services__modal-content'>
					<i
						onClick={() => toggleTab(0)}
						className='uil uil-times services__modal-close'
					></i>
					<h3 className='services__modal-title'>Первичная консультация</h3>
					<p className='services__modal-description'>
						Знакомимся и определяемся с дальнейшим маршрутом работы в терапии.
					</p>
					<ul className='services__modal-services grid'>
						<li className='services__modal-service'>
							<i class='uil uil-usd-circle services__modal-icon'></i>
							<p className='services__modal-info'>Стоимость - Бесплатно!</p>
						</li>
						<li className='services__modal-service'>
							<i className='uil uil-check-circle services__modal-icon'></i>
							<p className='services__modal-info'>В рамках реализации социального проекта при поддержке Опоры России</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Service_1
