import React from 'react'

const Social = () => {
	return (
		<div className='home__social'>
			<a
				href='https://t.me/malenkievnvch'
				className='home__social-icon'
				target='_blank'
				rel='noreferrer'
			>
				<i className='uil uil-telegram'></i>
			</a>

			<a
				href='https://vk.com/id340611578'
				className='home__social-icon'
				target='_blank'
				rel='noreferrer'
			>
				<i className='uil uil-vk'></i>
			</a>

			<a
				href='https://www.instagram.com/invites/contact/?i=15jwoiag7rjy2&utm_content=2rq7dkn'
				className='home__social-icon'
				target='_blank'
				rel='noreferrer'
			>
				<i className='uil uil-instagram-alt'></i>
			</a>
		</div>
	)
}

export default Social
