import React from 'react'
import AboutProfileImg from '../../assets/profile3.jpg'
import Info from './Info'
import './about.css'

const About = () => {
	return (
		<section className='about section' id='about'>
			<h2 className='section__title'>Обо Мне</h2>
			<span className='section__subtitle'>Основная информация</span>

			<div className='about__container container grid'>
				<img src={AboutProfileImg} alt='about' className='about__img' />
				<div className='about__data'>
					<Info />
					<p className='about__description'>
						Я детский психолог, учитель начальных классов и мама 3х детей.
						Представляю комитет по социальному предпринимательству ООО МСП РОО "ОПОРА РОССИИ" Новочеркасского отделения, являюсь членом Союза
						«Торгово-промышленной палаты Российской Федерации». ‌Являюсь
						сторонником политической партии Единая Россия, автором социального
						проекта "Маленькие Волшебники", финалистом межрегиональной премии
						"Женщина Лидер" в номинации "Образование и наука", состою
						в Антикоррупционной хартии российского бизнеса. Основатель и руководитель детского развития и диагностико-коррекционного центра. Я рада помочь Вам
						в решении Ваших вопросов!
					</p>
					<a
						href='https://taplink.cc/malenkiev_nvch'
						target='_blank'
						className='button button--flex'
					>
						Больше<i className='bx bx-file-blank button__icon'></i>
					</a>
				</div>
			</div>
		</section>
	)
}

export default About
