import React, { useState } from 'react'
import './services.css'

const Service_2 = () => {
	const [toggleState, setToggleState] = useState(0)
	const toggleTab = index => {
		setToggleState(index)
	}
	return (
		<div className='services__content'>
			<div>
				<i className='bx bx-spreadsheet services__icon'></i>
				<h3 className='services__title'>
					Стандартная <br /> Консультация
				</h3>
			</div>
			<span onClick={() => toggleTab(2)} className='services__button'>
				Смотреть<i className='uil uil-arrow-right services__button-icon'></i>
			</span>

			<div
				className={
					toggleState === 2 ? 'services__modal active-modal' : 'services__modal'
				}
			>
				<div className='services__modal-content'>
					<i
						onClick={() => toggleTab(0)}
						className='uil uil-times services__modal-close'
					></i>
					<h3 className='services__modal-title'>Стандартная консультация</h3>
					<p className='services__modal-description'></p>
					<ul className='services__modal-services grid'>
						<li className='services__modal-service'>
							<i class='uil uil-usd-circle services__modal-icon'></i>
							<p className='services__modal-info'>Стоимость - 3000 рублей</p>
						</li>
						<li className='services__modal-service'>
							<i className='uil uil-check-circle services__modal-icon'></i>
							<p className='services__modal-info'>Длительность - 1 час</p>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Service_2
