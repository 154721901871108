import React from "react";
import "./scrollup.css"

const ScrollUp = () => {
     window.addEventListener("scroll", function () {
         const scrollUp = document.querySelector(".scroll-up");
         if (this.scrollY >= 560) scrollUp.classList.add ("show-scroll")
         else scrollUp.classList.remove("show-scroll")
     });
    return (
        <a href="#" className='scroll-up'>
            <i className='uil uil-arrow-up scroll-up__icon'></i>
        </a>
    )
}

export default ScrollUp