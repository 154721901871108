import React from 'react'
import './loading.css'

const Loading = () => {
	return (
		<div className='overlay_loader'>
			<div className='loader'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	)
}

export default Loading
