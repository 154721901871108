export const Data = [
	{
		id: 1,
		title: 'Анастасия',
		description:
			'До начала работы с вами моя жизнь мне казалась непонятной и смешанной, точно так же как состояние. Теперь я начинаю себя ощущать все легче и яснее после каждого сеанса с вами. Мы проработали мои отношения с родными и близкими.',
	},
	{
		id: 2,
		title: 'Андрей',
		description:
			'Сын начал ходить к тебе на терапию. Стал увереннее в себе, стал делиться и объяснять свои эмоции и причину своего поведения. С ним стало легче договариваться. Благодарю за проведенную работу, и за то что помогла меня сблизить с сыном. Рекомендую как наилучшего специалиста, психолога, педагога и просто ДРУГА.',
	},
	{
		id: 3,
		title: 'Алексей',
		description:
			'Хочу выразить Александре огромную благодарность за помощь в наших взаимоотношениях с сыном. Вы помогли нам с адаптацией в новом школьном коллективе, отношениях с учителями и стабилизировала взаимоотношения в семье. Отдельное спасибо за профессионализм и помощь!',
	},
	{
		id: 4,
		title: 'Юлия',
		description:
			'Базовая Александра Александровна прекрасный педагог и человек. Является квалифицированным специалистом своего дела. Чутко подошла в обучению и нужному направлению моего ребенка. Четка определила причинно следственную связь и помогла с развитием речи. За 3 года совместной работы я очень довольна результатом.',
	},
	{
		id: 5,
		title: 'Валерия',
		description:
			'Сын ходит 3 месяца. У него была фобия (боязнь собак). Говорил о них постоянно и на улице только на них обращал внимание. Вот уже второй месяц я ни разу от него не услышала что он боится собак, спокойно ходит мимо них, не обращая внимания. Стал больше рисовать дома, разукрашивать.',
	},
	{
		id: 6,
		title: 'Ольга',
		description:
			'Александра Александровна спасибо вам огромное за ваш труд и индивидуальный подход к моему ребенку! Совсем немного времени и вот ребенку уже легко дается выучить стишок. Научились общаться и контактировать с другими людьми хотя раньше нам это было не интересно. ',
	},
	{
		id: 7,
		title: 'Екатерина',
		description:
			'Хочу поблагодарить вас, за 4 занятия всего лишь раз в неделю, мой ребенок стал более спокойным, у нас появился диалог мамы и сына, он стал отчетливо давать оценку свои действиям, обратились мы с тем что он говорил "я урод" "я не могу" "у меня не выйдет" и эти слова меня очень цепляли. Проблема ушла, нет больше таких разговоров с его стороны',
	},
	{
		id: 7,
		title: 'Марина',
		description:
			'Спасибо вам Александра Александровна, не нарадуюсь успехами своего сына и все благодаря вам и это без преувеличения! Вы вернули мир в нашу семью. Теперь подготовка домашнего задания проходит с удовольствием.',
	},
]
