import Work1 from '../../img/img1.jpg'
import Work10 from '../../img/img10.jpg'
import Work14 from '../../img/img13.jpg'
import Work15 from '../../img/img14.jpg'
import Work2 from '../../img/img2.jpg'
import Work8 from '../../img/img20.jpg'
import Work9 from '../../img/img21.jpg'
import Work12 from '../../img/img23.jpg'
import Work16 from '../../img/img24.jpg'
import Work18 from '../../img/img31.jpg'
import Work20 from '../../img/img33.jpg'
import Work4 from '../../img/img4.jpg'
import Work5 from '../../img/img5.jpg'
import Work6 from '../../img/img6.jpg'
import Work13 from '../../img/img7.jpg'
import Work11 from '../../img/img8.jpg'
import Work7 from '../../img/img9.jpg'
import Work21 from '../../img/img34.jpg'
import Work22 from '../../img/img35.jpg'
import Work23 from '../../img/img36.jpg'
import Work24 from '../../img/img37.jpg'
import Work25 from '../../img/img38.jpg'
import Work26 from '../../img/img39.jpg'
import Work27 from '../../img/img40.jpg'

export const projectsData = [
	{
		id: 1,
		img: Work1,
		category: 'Работа',
	},
	{
		id: 2,
		img: Work2,
		category: 'Работа',
	},
	{
		id: 4,
		img: Work4,
		category: 'Работа',
	},
	{
		id: 5,
		img: Work10,
		category: 'Работа',
	},
	{
		id: 6,
		img: Work6,
		category: 'Работа',
	},
	{
		id: 7,
		img: Work5,
		category: 'Работа',
	},
	{
		id: 8,
		img: Work7,
		category: 'Работа',
	},
	{
		id: 9,
		img: Work8,
		category: 'Работа',
	},
	{
		id: 10,
		img: Work9,
		category: 'Работа',
	},
	{
		id: 11,
		img: Work11,
		category: 'Работа',
	},
	{
		id: 12,
		img: Work12,
		category: 'Работа',
	},
	{
		id: 13,
		img: Work13,
		category: 'Работа',
	},
	{
		id: 14,
		img: Work14,
		category: 'Работа',
	},
	{
		id: 15,
		img: Work15,
		category: 'Работа',
	},
	{
		id: 16,
		img: Work16,
		category: 'Я и Семья',
	},
	{
		id: 17,
		img: Work18,
		category: 'Работа',
	},
	{
		id: 18,
		img: Work20,
		category: 'Работа',
	},
	{
		id: 19,
		img: Work21,
		category: 'Общ. деятельность',
	},
	{
		id: 20,
		img: Work22,
		category: 'Общ. деятельность',
	},
	{
		id: 21,
		img: Work23,
		category: 'Работа',
	},
	{
		id: 22,
		img: Work24,
		category: 'Общ. деятельность',
	},
	{
		id: 23,
		img: Work25,
		category: 'Работа',
	},
	{
		id: 24,
		img: Work26,
		category: 'Работа',
	},
	{
		id: 25,
		img: Work27,
		category: 'Работа',
	},
]

export const projectsNav = [
	{
		name: 'Работа',
	},
	{
		name: 'Я и Семья',
	},
	{
		name: 'Общ. деятельность',
	},
	{
		name: 'Все',
	},
	
]
