import React, { useState } from 'react'

const WorkItems = ({ item }) => {
	const [toggleState, setToggleState] = useState(0)
	const toggleTab = index => {
		setToggleState(index)
	}
	return (
		<div className='work__card' key={item.id}>
			<img className='work__img' src={item.img} alt='' />
			<span className='work__button' onClick={() => toggleTab(1)}>
				Смотреть <i className='bx bx-right-arrow-alt work__button-icon'></i>
			</span>
			<div
				className={
					toggleState === 1 ? 'work__modal active-modal' : 'work__modal'
				}
			>
				<div className='work__modal-content'>
					<i
						onClick={() => toggleTab(0)}
						className='uil uil-times work__modal-close'
					></i>
					<img className='work__modal-f' src={item.img} />
				</div>
			</div>
		</div>
	)
}

export default WorkItems
