import React from 'react'
import './services.css'

import Service_1 from './Service_1'
import Service_2 from './Service_2'
import Service_3 from './Service_3'
import Service_4 from './Service_4'
import Service_5 from './Service_5'
import Service_6 from './Service_6'
import Service_7 from './Service_7'
import Service_8 from './Service_8'

const Services = () => {
	return (
		<section className='services section' id='services'>
			<h2 className='section__title'>Мои Услуги</h2>
			<span className='section__subtitle'>Что я предлагаю</span>
			<div className='services__container container grid'>
				<Service_1 />
				<Service_2 />
				<Service_3 />
				<Service_4 />
				<Service_5 />
				<Service_6 />
				<Service_7 />
				<Service_8 />
			</div>
			<div className='services__banner container'>
				<div className='services__inner'>
					<div className='services__inner-top'>
						{/* <div>Бесплатно</div> */}
						<h1 className='services__inner-top-title'>
						Бесплатные консультации и диагностика специалистов при поддержке организации "Опора России"
						</h1>
					</div>
					<div className='services__inner-middle'>
						<div className='services__inner-middle-text'>
							<p>
								Дефектолог, логопед, детский и семейный психолог.
							</p>
							<ul>
								<li>ул. Дубовского 15</li>
								<li>ул. Красноармейская 10а</li>
								<li>ул. Визирова 11</li>
								<li>ул. Привокзальная 12</li>
								<li>ул. Мацоты 52</li>
								<li>п. Казачьи Лагеря</li>
							</ul>
						</div>
					</div>
					<div className='services__inner-bottom'>
						<span>
							Предварительная запись <b>обязательна,</b> тел.{' '}
							<a
								className='services__inner-bottom-tel'
								href='tel:8-989-613-40-04'
							>
								8-989-613-40-04
							</a>
							<b>,  </b>
							<a
								className='services__inner-bottom-tel'
								href='tel:8-903-404-79-32'
							>
								8-903-404-79-32
							</a>
						</span>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Services
