import React, { useEffect, useState } from 'react'
import './App.css'

import About from './components/about/About'
import Comments from './components/comments/Comments'
import Contact from './components/contact/Contact'
import Experience from './components/experience/Experience'
import Footer from './components/footer/Footer'
import Header from './components/header/Header'
import Home from './components/home/Home'
import Link from './components/link/Link'
import Loading from './components/loader/Loading'
import ScrollUp from './components/scrollup/ScrollUp'
import Services from './components/services/Services'
import Work from './components/work/Work'

const App = () => {
	const [isLoading, isSetLoading] = useState(false)
	useEffect(() => {
		isSetLoading(true)
		setTimeout(() => {
			isSetLoading(false)
		}, 1800)
	}, [])
	return (
		<div>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<Header />
					<main className='main'>
						<Home />
						<About />
						<Services />
						<Experience />
						<Work />
						<Comments />
						{/* <Contact /> */}
					</main>
					<Footer />
					<Link />
					<ScrollUp />
				</>
			)}
		</div>
	)
}

export default App
