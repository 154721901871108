import React, { useState } from 'react'
import './experience.css'

const Experience = () => {
	const [toggleState, setToggleState] = useState(1)
	const toggleTab = index => {
		setToggleState(index)
	}
	return (
		<section className='exp section'>
			<h2 className='section__title'>Опыт работы</h2>
			<span className='section__subtitle'>года</span>

			<div className='exp__container container'>
				<div className='exp__tabs'>
					<div
						className={
							toggleState === 1
								? 'exp__button exp__active button--flex'
								: 'exp__button button--flex'
						}
						onClick={() => toggleTab(1)}
					>
						<i className='uil uil-graduation-cap exp__icon'></i> Образование
					</div>
					<div
						className={
							toggleState === 2
								? 'exp__button exp__active button--flex'
								: 'exp__button button--flex'
						}
						onClick={() => toggleTab(2)}
					>
						<i className='uil uil-briefcase-alt exp__icon'></i> Общ.
						Деятельность
					</div>
				</div>

				<div className='exp__sections'>
					<div
						className={
							toggleState === 1
								? 'exp__content exp__content-active'
								: 'exp__content'
						}
					>
						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Учитель нач. классов</h3>
								<span className='exp__subtitle'>Колледж</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2006 - 2011 гг.
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>

						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Педагогика и практич. психология</h3>
								<span className='exp__subtitle'>Университет</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2012 - 2016 гг.
								</div>
							</div>
						</div>

						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Коррекционный педагог</h3>
								<span className='exp__subtitle'>Повыш. квалификации</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2018 г.
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>

						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Инклюзия в образовании</h3>
								<span className='exp__subtitle'>Повыш. квалификации</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2019 г.
								</div>
							</div>
						</div>

						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Педагог - Психолог</h3>
								<span className='exp__subtitle'>
									Институт повышения квалификации НПИ
								</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2021 г.
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>

						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Менеджмент в Сфере Образования</h3>
								<span className='exp__subtitle'>
									Институт повышения квалификации НПИ
								</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2022 г.
								</div>
							</div>
						</div>
					</div>

					<div
						className={
							toggleState === 2
								? 'exp__content exp__content-active'
								: 'exp__content'
						}
					>
						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Воспитатель</h3>
								<span className='exp__subtitle'>Воспитатель детского сада</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2011
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>
						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Учитель</h3>
								<span className='exp__subtitle'>Учитель начальных классов</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2019
								</div>
							</div>
						</div>

						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Руководитель</h3>
								<span className='exp__subtitle'>
									Руководитель и учредитель ДРЦ "Школа Маленьких Волшебников"
								</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2019
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>

						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Волонтер</h3>
								<span className='exp__subtitle'>"Матери России"</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2022
								</div>
							</div>
						</div>

						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Член организации</h3>
								<span className='exp__subtitle'>"Опора России"</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2022
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>

						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Комитет по доп. образованию</h3>
								<span className='exp__subtitle'></span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2022
								</div>
							</div>
						</div>

						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Комитет по соц. предпринима<br/>тельству</h3>
								<span className='exp__subtitle'>Представляю комитет по соц. предпринима<br/>тельству ООО МСП РОО "ОПОРА РОССИИ" Новочеркасского отделения</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2024
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>

						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Член союза</h3>
								<span className='exp__subtitle'>Союз "Торгово-промышленной палаты Российской Федерации"</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2024
								</div>
							</div>
						</div>

						<div className='exp__data'>
							<div className='exp__item'>
								<h3 className='exp__title'>Состою в хартии</h3>
								<span className='exp__subtitle'>Антикоррупци-<br/>онная хартия российского бизнеса</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2023
								</div>
							</div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
						</div>
					
						<div className='exp__data'>
							<div></div>
							<div>
								<span className='exp__rounder'></span>
								<span className='exp__line'></span>
							</div>
							<div className='exp__item'>
								<h3 className='exp__title'>Сторонник партии</h3>
								<span className='exp__subtitle'>Являюсь сторонником партии Единая Россия, автором соц. проекта "Маленькие волшебники"</span>
								<div className='exp__calendar'>
									<i className='uil uil-calendar-alt'></i> 2024
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Experience
