import React from 'react'
import { Data } from './Data'
import './comments.css'

import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

const Comments = () => {
	return (
		<section className='comments container section' id='comments'>
			<h2 className='section__title'>Что говорят обо Мне</h2>
			<span className='section__subtitle'>Отзывы клиентов</span>

			<Swiper
				className='comments__container'
				loop={true}
				grabCursor={true}
				spaceBetween={24}
				pagination={{
					clickable: true,
				}}
				breakpoints={{
					576: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 48,
					},
				}}
				modules={[Pagination]}>
				{Data.map(({ id, title, description }) => {
					return (
						<SwiperSlide className='comments__card' key={id}>
							<i className='bx bxs-quote-alt-left comments__img'></i>
							<h3 className='comments__name'>{title}</h3>
							<p className='comments__description'>{description}</p>
						</SwiperSlide>
					)
				})}
			</Swiper>
			<div className='comments__link'>
				<a
					className='comments__button'
					target='_blank'
					href='https://t.me/+IgQ5cr7tSKQ3ODk6'
					rel='noreferrer'>
					Смотреть все отзывы{' '}
					<i className='bx bx-right-arrow-alt comments__button-icon'></i>
				</a>
			</div>
		</section>
	)
}

export default Comments
