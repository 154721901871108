import React from 'react'
import { Link } from 'react-scroll'

const Data = () => {
	return (
		<div className='home__data'>
			<h1 className='home__title'>Базовая Александра</h1>
			<h3 className='home__subtitle'>Педагог - психолог</h3>
			<p className='home__description'>
				Комплексная диагностика специалистов в области детского развития, заключения, рекомендательная работа, психокоррекция эмоционально-волевой сферы, когнитивных функций. Обучение специалистов в области детского развития.
			</p>
			<Link
				to='about'
				smooth={true}
				duration={150}
				offset={-100}
				className='button button--flex'
			>
				Смотреть <i className='bx bx-run button__icon'></i>
			</Link>
		</div>
	)
}

export default Data
