import React, { useState } from 'react'
import { Link } from 'react-scroll'
import './header.css'

const Header = () => {
	window.addEventListener('scroll', function () {
		const header = document.querySelector('.header')
		if (this.scrollY >= 80) header.classList.add('scroll-header')
		else header.classList.remove('scroll-header')
	})
	const [Toggle, showMenu] = useState(false)
	return (
		<header className='header'>
			<nav className='nav container'>
				<Link className='nav__logo' to='home' smooth={true} duration={150}>
					<span>bazovaya</span>
				</Link>
				<div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
					<ul className='nav__list grid'>
						<li className='nav__item active'>
							<Link
								to='home'
								duration={150}
								spy={true}
								smooth={true}
								offset={-100}
								className='nav__link'
							>
								<i className='uil uil-estate nav__icon'></i> Главная
							</Link>
						</li>
						<li className='nav__item'>
							<Link
								to='about'
								duration={150}
								spy={true}
								smooth={true}
								offset={-100}
								className='nav__link'
							>
								<i className='uil uil-user nav__icon'></i> Обо Мне
							</Link>
						</li>
						<li className='nav__item'>
							<Link
								to='services'
								duration={150}
								spy={true}
								smooth={true}
								offset={-100}
								className='nav__link'
							>
								<i className='uil uil-briefcase-alt nav__icon'></i> Услуги
							</Link>
						</li>
						<li className='nav__item'>
							<Link
								to='exp'
								duration={150}
								spy={true}
								smooth={true}
								offset={-100}
								className='nav__link'
							>
								<i className='uil uil-briefcase-alt nav__icon'></i> Опыт
							</Link>
						</li>
						<li className='nav__item'>
							<Link
								to='portfolio'
								duration={150}
								spy={true}
								smooth={true}
								offset={-100}
								className='nav__link'
							>
								<i className='uil uil-scenery nav__icon'></i> Портфолио
							</Link>
						</li>
					</ul>
					<i
						className='uil uil-times nav__close'
						onClick={() => showMenu(!Toggle)}
					></i>
				</div>
				<div className='nav__toggle' onClick={() => showMenu(!Toggle)}>
					<i className='uil uil-apps'></i>
				</div>
			</nav>
		</header>
	)
}

export default Header
