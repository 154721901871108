import React from 'react'
import { Link } from 'react-scroll'
import './footer.css'

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='footer__container container'>
				<h1 className='footer__title'>Александра Базовая</h1>

				<ul className='footer__list'>
					<li>
						<Link
							to='home'
							smooth={true}
							duration={150}
							offset={-100}
							className='footer__link'
						>
							Главная
						</Link>
					</li>

					<li>
						<Link
							to='services'
							smooth={true}
							duration={150}
							offset={-100}
							className='footer__link'
						>
							Услуги
						</Link>
					</li>

					<li>
						<Link
							to='portfolio'
							smooth={true}
							duration={150}
							offset={-100}
							className='footer__link'
						>
							Портфолио
						</Link>
					</li>
				</ul>

				<div className='footer__social'>
					<a
						href='https://t.me/malenkiev'
						className='footer__social-link'
						target='_blank'
						rel='noreferrer'
					>
						<i className='bx bxl-telegram'></i>
					</a>

					<a
						href='https://vk.com/id340611578'
						className='footer__social-link'
						target='_blank'
						rel='noreferrer'
					>
						<i class='bx bxl-vk'></i>
					</a>

					<a
						href='https://www.instagram.com/invites/contact/?i=15jwoiag7rjy2&utm_content=2rq7dkn'
						className='footer__social-link'
						target='_blank'
						rel='noreferrer'
					>
						<i className='bx bxl-instagram'></i>
					</a>
				</div>
				<span className='footer__copy'>
					Bazovaya. &#169; 2023-2024. Все права защищены.
				</span>
			</div>
		</footer>
	)
}

export default Footer
